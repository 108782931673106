<template>
    <div>
        <v-img
            :src='urlImagen+"/"+imagen+".jpg"'
            aspect-ratio="1.5"
            class="grey lighten-2"
            contain
        ></v-img>
    </div>
</template>

<script>
export default {
  name: 'RocaFirme',

  data: () => ({
    urlImagen: process.env.VUE_APP_IMAGEN,
    imagen: "EnConstruccion"
  }),
};
</script>